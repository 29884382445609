<template>
  <AuthContainer :title="$route.query.title" @onBack="handleBack">
    <AuthBackContainer :title="$route.query.title"><!--錯誤標題-->
        <!--ErrorPage-->
        <article class="h-inherit pt-20 md:pt-0 audit _abnormal md:bg-white">
          <div class="audit-wrap">
            <section class="audit-ill">
             <div v-if="$route.query.desc == 1">
               <div><strong>請洽詢揪美美客服人員專線，(02)5569-7585</strong></div>
               <div>E-mail: <a href='mailto:service@mirada.com.tw'>service@mirada.com.tw</a></div>
               <div>服務時間:周一至周五 09:00 - 17:00</div>
               <br/>
               </div><!--錯誤訊息-->
              <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
            </section>
            <section class="audit-con">
            </section>
            <router-link :to="{ name: 'home' }" class="btn-forms py-2 px-24">
              回首頁
            </router-link>
          </div>
        </article>
    </AuthBackContainer>
  </AuthContainer>
</template>
<script>
import AuthContainer from '@/components/auth/AuthContainer'
import AuthBackContainer from '@/components/auth/AuthBackContainer-noPadding'

export default {
  name: 'errorPage',
  components: {
    AuthContainer,
    AuthBackContainer
  },
  methods: {
    handleBack() {
      this.$router.push('/')
    }
  }
}
</script>